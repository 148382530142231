const isProduction = process.env.NODE_ENV === 'production'

export default {
    log: (message, ...args) => {
        if (!isProduction) {
            console.log(message, ...args)
        }
    },
    error: (err) => {
        if (!isProduction) {
            console.error(err)
        }
    }
}
