import gql from 'graphql-tag'
import { mediaFullFragment } from '../fragments/fragments.js'

export const SWITCH_TO_ARTIST_PROFILE = gql`
    mutation SwitchToArtistProfile {
        switchToArtistProfile @client
    }
`

export const UPLOAD_IMAGE = gql`
    mutation Upload($file: Upload!, $refId: ID, $ref: String, $field: String) {
        upload(file: $file, refId: $refId, ref: $ref, field: $field) {
            name
            id
            previewUrl
            url
            formats
        }
    }
`
export const CREATE_VENUE_PROFILE = gql`
    mutation CreateVenue($input: createVenueInput) {
        createVenueProfile(input: $input) {
            type: __typename
            ... on ResponseError {
                errors {
                    message
                    code
                }
            }
            ... on createVenuePayload {
                venue {
                    id
                    media {
                        id
                    }
                }
            }
        }
    }
`
export const UPDATE_VENUE_PROFILE = gql`
    mutation UpdateVenue($input: updateVenueInput!) {
        updateVenueProfile(input: $input) {
            type: __typename
            ... on ResponseError {
                errors {
                    message
                    code
                    field
                }
            }
            ... on updateVenuePayload {
                venue {
                    id
                    name
                    description
                    categories
                    priceMin
                    priceMax
                    priceMinWeekend
                    priceMaxWeekend
                    status

                    contact {
                        phone
                        email
                        web
                    }
                    capacity
                    address {
                        zip
                        city
                        street
                    }
                    ${mediaFullFragment}
                    avatar @client
                    coverUrl @client
                }
            }
        }
    }
`

export const CREATE_ARTIST_PROFILE = gql`
    mutation CreateArtist($input: createArtistInput!) {
        createArtistProfile(input: $input) {
            type: __typename
            ... on ResponseError {
                errors {
                    message
                    code
                }
            }
            ... on createArtistPayload {
                artist {
                    id
                    media {
                        id
                    }
                }
            }
        }
    }
`
export const UPDATE_ARTIST_PROFILE = gql`
    mutation UpdateArtist($input: updateArtistInput!) {
        updateArtistProfile(input: $input) {
            type: __typename
            ... on ResponseError {
                errors {
                    message
                    code
                }
            }
            ... on updateArtistPayload {
                artist {
                    id
                    name
                    description
                    categories
                    priceMin
                    priceMax
                    priceMinWeekend
                    priceMaxWeekend
                    status
                    contact {
                        phone
                        email
                        web
                    }
                    location
                    worklocation
                    ${mediaFullFragment}
                    avatar @client
                    coverUrl @client
                }
            }
        }
    }
`

export const UPDATE_FAN_PROFILE = gql`
    mutation UpdateFan($input: updateFanInput) {
        updateFanProfile(input: $input) {
            type: __typename
            ... on ResponseError {
                errors {
                    message
                    code
                }
            }
            ... on updateFanPayload {
                fan {
                    id
                    name
                    description
                    interests
                    status
                    ${mediaFullFragment}
                    avatar @client
                    coverUrl @client
                }
            }
        }
    }
`
