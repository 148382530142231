// import authenticated from '../middleware/authenticated'

const Start = () => import('@/views/Start')
const Inbox = () => import('@/views/Inbox')
const Login = () => import('@/views/auth/Login')
const Event = () => import('@/views/events/Event')
const Dashboard = () => import('@/views/Dashboard')
const Events = () => import('@/views/events/Events')
const Walkthrough = () => import('@/views/Walkthrough')
const MyEvents = () => import('@/views/events/MyEvents')
const MyProfile = () => import('@/views/profile/MyProfile')
const MyRequests = () => import('@/views/events/MyRequests')
const Register = () => import('@/views/auth/register/Register')
const EditProfile = () => import('@/views/profile/EditProfile')
const ShowProfile = () => import('@/views/profile/ShowProfile')
const ResetPassword = () => import('@/views/auth/ResetPassword')
const UpdatePassword = () => import('@/views/auth/UpdatePassword')
const VerifyPayment = () => import('@/views/payment/VerifyPayment')
const CreateEvent = () => import('@/views/create-event/CreateEvent')
const IncomingRequests = () => import('@/views/events/IncomingRequests')
const OutgoingRequests = () => import('@/views/events/OutgoingRequests')
const EditEvent = () => import('@/views/create-event/edit-event/EditEvent')
const NewConversation = () => import('@/views/conversation/NewConversation')
const RegisterVenue = () => import('@/views/profile/register/RegisterVenue')
const RegisterArtist = () => import('@/views/profile/register/RegisterArtist')
const RequestConversation = () => import('@/views/conversation/RequestConversation')
export default [
    {
        path: '/',
        component: Start,
        meta: {
            hideForAuth: true
        }
    },
    {
        path: '/success',
        component: VerifyPayment
    },
    {
        path: '/login',
        component: Login,
        meta: {
            hideForAuth: true
        }
    },
    {
        path: '/register',
        component: Register,
        meta: {
            hideForAuth: true
        }
    },
    {
        path: '/walkthrough',
        component: Walkthrough,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'Dashboard',
        path: '/dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true
            /* middleware: [
                authenticated
            ] */
        }
    },
    {
        path: '/profile/me',
        component: MyProfile,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'edit',
                component: EditProfile,
                props: true
            }
        ]
    },
    {
        path: '/profile/:type/:id',
        component: ShowProfile,
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/create-event',
        component: CreateEvent,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/register-artist',
        component: RegisterArtist,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/register-venue',
        component: RegisterVenue,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/reset-password',
        component: ResetPassword
    },
    {
        path: '/update-password',
        component: UpdatePassword
    },
    {
        path: '/events',
        component: Events,
        children: [
            { path: '', redirect: 'calendar' },
            { path: 'calendar', component: MyEvents },
            {
                path: 'requests',
                component: MyRequests,
                children: [
                    { path: '', redirect: 'incoming' },
                    { path: 'incoming', component: IncomingRequests },
                    { path: 'outgoing', component: OutgoingRequests }
                ]
            }
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/conversation/:id',
        component: NewConversation,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/events/event/:id',
        component: Event,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/edit-event/:id',
        component: EditEvent,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/inbox',
        component: Inbox,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/request/:id',
        component: RequestConversation,
        name: 'request',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/conversation',
        name: 'conversation',
        component: NewConversation,
        meta: {
            requiresAuth: true
        }
    }
]
