<template>
    <button
        :class="classes"
        :type="type"
        v-bind="$attrs"
        :disabled="disabled"
        @click="$emit('click', $event)"
    >
        <div class="c-button__prepend">
            <slot name="prepend"></slot>
        </div>
        {{ label }}
        <div>
            <slot name="append" />
        </div>
    </button>
</template>

<script>
export default {
    name: 'Button',
    props: {
        type: {
            type: String,
            default: 'button'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        fluid: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            required: true
        },
        primary: {
            type: Boolean,
            default: false
        },
        beige: {
            type: Boolean,
            default: false
        },
        light: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: 'medium',
            validator: function(value) {
                return ['small', 'medium', 'large'].indexOf(value) !== -1
            }
        },
        square: {
            type: Boolean,
            default: false
        },
        bigSquareFixedBottom: {
            type: Boolean,
            default: false
        }
    },
    emits: ['click'],
    computed: {
        classes() {
            return {
                'c-button': true,
                'button--primary': this.primary,
                'button--secondary': !this.primary,
                'button--beige': this.beige,
                [`button--${this.size}`]: true,
                'button--square': this.square,
                'button--fluid': this.fluid,
                'button--big-square-fixed-bottom': this.bigSquareFixedBottom,
                'button--light': this.light
            }
        }
    },
    methods: {
        onClick() {
            this.$emit('click')
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../sass/variables.scss';
.c-button {
    border: none;
    border-radius: 3rem;
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    column-gap: 0.5rem;
    outline: 0;
    line-height: 0.9rem;

    &:disabled {
        opacity: 0.5;
    }
    &__prepend {
        display: grid;
        place-items: center;
    }

    &__append {
        display: grid;
        place-items: center;
        overflow: hidden;
    }
}

.button--square {
    border-radius: 5px;
}

.button--primary {
    color: $beige;
    background-color: $primary;
    border: 1px solid $primary;
}

.button--secondary {
    color: $primary;
    background-color: transparent;
    border: 1px solid $primary;
}
.button--beige {
    color: $primary;
    background-color: $beige;
    border: 1px solid $primary;
}

.button--light {
    color: $primary;
    background-color: $light;
    border: 1px solid $light;
}
.button--small {
    font-size: 14px;
    padding: 0.5rem 1rem;
}

.button--medium {
    font-size: 1.2rem;
    padding: 1rem 1.5rem;
}

.button--large {
    font-size: 20px;
    padding: 1.5rem 2rem;
    border: 2px solid $primary;
}

.button--fluid {
    width: 100%;
}

.button--big-square-fixed-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    border-radius: 0;
    width: 100%;
    height: 90px;
    z-index: 10000002;
}
</style>
