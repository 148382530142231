<script>
import { AUTH_TOKEN, PROFILE_CONTEXT } from '@/constants/settings'
import { UPDATE_LOGGED_IN_STATE_MUTATION } from '@/graphql/mutations/local.js'
// import { onLogin, onLogout } from '@/vue-apollo.js'
import { restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client'
import { LOGIN_USER } from '@/views/auth/graphql/mutations'
import logger from '@/utils/logger.js'
import gql from 'graphql-tag'

const RESET_USER_PASSWORD = gql`
    mutation ResetUserPassword($email: String!) {
        resetUserPassword(email: $email)
    }
`

const UPDATE_PASSWORD = gql`
    mutation UpdatePassword($email: String!, $token: String!, $password: String!) {
        updatePassword(email: $email, token: $token, password: $password) {
            ... on UpdatePasswordResponse {
                success
            }
            ... on ResponseError {
                errors {
                    message
                    code
                    field
                }
            }
        }
    }
`

export default {
    provide() {
        return {
            resetUserPassword: this.resetUserPassword,
            updateUserPassword: this.updateUserPassword,
            onLogin: this.onLogin,
            login: this.login,
            logout: this.logout
        }
    },
    methods: {
        resetUserPassword(email) {
            return this.$apollo.mutate({
                mutation: RESET_USER_PASSWORD,
                variables: {
                    email
                }
            })
        },
        async updateUserPassword(email, token, password) {
            return this.$apollo.mutate({
                mutation: UPDATE_PASSWORD,
                variables: {
                    email,
                    token,
                    password
                }
            })
        },
        async login(identifier, password) {
            try {
                const result = await this.$apollo.mutate({
                    mutation: LOGIN_USER,
                    variables: {
                        input: {
                            identifier,
                            password
                        }
                    }
                })

                const { jwt, user } = result.data.login
                this.onLogin(jwt, user)
                return result.data
            } catch (error) {
                logger.log('Error in AuthProvider when logging in', {
                    error
                })
                return { login: null, errors: error.graphQLErrors }
            }
        },
        async onLogin(token, user) {
            if (typeof localStorage !== 'undefined' && token) {
                localStorage.setItem(AUTH_TOKEN, token)
                this.$apollo.mutate({
                    mutation: UPDATE_LOGGED_IN_STATE_MUTATION,
                    variables: {
                        state: !!localStorage.getItem('apollo-token')
                    }
                })

                const context = localStorage.getItem(PROFILE_CONTEXT)
                if (!context) {
                    localStorage.setItem(
                        PROFILE_CONTEXT,
                        JSON.stringify({
                            type: 'fan',
                            id: user.fan.id
                        })
                    )
                }
            }
            // wsClient.close(true)
            if (this.$apollo.provider.defaultClient.wsClient) {
                // enable wsClient.reconnect if online
                this.$apollo.provider.defaultClient.wsClient.reconnect = true
                restartWebsockets(this.$apollo.provider.defaultClient.wsClient)
            }
            try {
                await this.$apollo.provider.defaultClient.cache.reset()
            } catch (error) {
                logger.log('%cError on cache reset (login)', 'color: orange;', error.message)
            }
        },
        async logout() {
            logger.log('logging out')
            if (typeof localStorage !== 'undefined') {
                localStorage.removeItem(AUTH_TOKEN)
                this.$apollo.mutate({
                    mutation: UPDATE_LOGGED_IN_STATE_MUTATION,
                    variables: {
                        state: !!localStorage.getItem('apollo-token')
                    }
                })
                localStorage.removeItem(PROFILE_CONTEXT)
                localStorage.clear()

                await this.$apollo.provider.defaultClient.cache.reset()
            }
            if (this.$apollo.provider.defaultClient.wsClient) {
                // restartWebsockets(this.$apollo.provider.defaultClient.wsClient)
                // close connection and prevent reconnect
                this.$apollo.provider.defaultClient.wsClient.reconnect = false
                this.$apollo.provider.defaultClient.wsClient.close(true)
                // prevent restart on logout?
                // restartWebsockets(this.$apollo.provider.defaultClient.wsClient)
            }
            // try {
            //     // location.reload()
            // } catch (error) {
            //     logger.log('%cError on cache reset (logout)', 'color: orange;', error.message, {
            //         error
            //     })
            // }
        }
    },
    render() {
        return this.$slots.default
    }
}
</script>
