import gql from 'graphql-tag'
import {
    addressFragment,
    contactFragment,
    mediaFullFragment,
    priceRangeFragment,
    profileMetaFragment
} from '../fragments/fragments'

export const GET_CURRENT_PROFILE = gql`
    query GetCurrentProfile {
        currentProfile @client
    }
`

export const ME = gql`
    query Me {
        me {
            id
            username
            email
            confirmed
            blocked
            fan {
                id
                status
                media {
                    id
                    profile {
                        id
                        url
                    }
                }
                name
                avatar @client
                displayName @client
            }
            artists {
                id
                status
                media {
                    id
                    profile {
                        id
                        url
                    }
                }
                name
                avatar @client
                displayName @client
            }
            venues {
                id
                status
                media {
                    id
                    profile {
                        id
                        url
                    }
                }
                name
                avatar @client
                displayName @client
            }
        }
    }
`

export const MY_FAN_PROFILE = gql`
    query MyFanProfile {
        myProfile {
            categories: interests
            ${profileMetaFragment}
            ${mediaFullFragment}
            avatar @client
            coverUrl @client
            displayName @client
        }
    }
`

export const MY_ARTIST_PROFILE = gql`
    query MyArtistProfile {
        myArtistProfile {
            categories
            location
            worklocation
            ${profileMetaFragment}
            ${priceRangeFragment}
            ${mediaFullFragment}
            ${contactFragment}
            ${addressFragment}
            avatar @client
            coverUrl @client
            displayName @client
        }
    }
`

export const MY_VENUE_PROFILE = gql`
    query MyVenueProfile {
        myVenueProfile {
            categories
            location
            capacity
            ${profileMetaFragment}
            ${priceRangeFragment}
            ${mediaFullFragment}
            ${contactFragment}
            ${addressFragment}
            avatar @client
            coverUrl @client
            displayName @client
        }

    }
`

export const GET_FAN = gql`
    query Fan($id: ID!) {
        fan(id: $id) {
            name
            id
            description
            meta
            ${mediaFullFragment}
            avatar @client
            coverUrl @client
        }
    }
`

export const GET_ARTIST = gql`
    query Artist($id: ID!) {
        artist(id: $id) {
            name
            id
            description
            meta
            ${mediaFullFragment}
            avatar @client
            coverUrl @client
        }
    }
`

export const GET_VENUE = gql`
    query Venue($id: ID!) {
        venue(id: $id) {
            name
            id
            description
            meta
            ${mediaFullFragment}
            avatar @client
            coverUrl @client
        }
    }
`

export const FOLLOW_PROFILE = gql`
    mutation FollowProfile($input: followInput!) {
        followProfile(input: $input)
    }
`

export const UNFOLLOW_PROFILE = gql`
    mutation UnfollowProfile($input: followInput!) {
        unFollowProfile(input: $input)
    }
`
