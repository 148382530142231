import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { IntrospectionFragmentMatcher, InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link'
import { createUploadLink } from 'apollo-upload-client'
import {
    createApolloClient
    // restartWebsockets,
} from 'vue-cli-plugin-apollo/graphql-client'
import { AUTH_TOKEN, PROFILE_CONTEXT } from './constants/settings'

import introspectionQueryResultData from './fragmentTypes.json'

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
})

const cache = new InMemoryCache({ fragmentMatcher })

// Install the vue plugin
Vue.use(VueApollo)

// Name of the localStorage item

// Http endpoint
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || 'https://goo-test.herokuapp.com/graphql'
// const httpLink = new HttpLink({ uri: httpEndpoint })
const httpLink = createUploadLink({
    uri: httpEndpoint
})

const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    const context = localStorage.getItem(PROFILE_CONTEXT)
    const token = localStorage.getItem('apollo-token')
    if (context && token) {
        const { id, type } = JSON.parse(context)
        operation.setContext({
            headers: {
                authorization: `Bearer ${token}`,
                'X-profile-type': type,
                'X-profile-id': id
            }
        })
    }

    return forward(operation)
})

// Config
const defaultOptions = {
    // You can use `https` for secure connection (recommended in production)
    // httpEndpoint,
    // You can use `wss` for secure connection (recommended in production)
    // Use `null` to disable subscriptions
    wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'wss://goo-test.herokuapp.com/graphql',
    // LocalStorage token
    tokenName: AUTH_TOKEN,
    // Enable Automatic Query persisting with Apollo Engine
    persisting: false,
    // Use websockets for everything (no HTTP)
    // You need to pass a `wsEndpoint` for this to work
    websocketsOnly: false,
    // Is being rendered on the server?
    ssr: false,
    cache,

    // Override default apollo link
    // note: don't override httpLink here, specify httpLink options in the
    // httpLinkOptions property of defaultOptions.
    link: ApolloLink.from([authMiddleware, httpLink]),
    defaultHttpLink: false
    // link: concat(authMiddleware, httpLink)

    // Override default cache
    // cache: myCache

    // Override the way the Authorization header is set
    // getAuth: (tokenName) => ...

    // Additional ApolloClient options
    // apollo: { ... }

    // Client local data (see apollo-link-state)
    // clientState: { resolvers: { ... }, defaults: { ... } }
}

// Call this in the Vue app file
export function createProvider(options = {}) {
    // Create apollo client
    const { apolloClient, wsClient } = createApolloClient({
        ...defaultOptions,
        ...options
    })
    // https://github.com/apollographql/apollo-client/issues/3967 - updated ws params, check example: If so then on WebSocketLink you can only pass tokens on connect.
    wsClient.lazy = true
    wsClient.reconnect = !!localStorage.getItem('apollo-token')

    wsClient.use([
        {
            applyMiddleware(operationOptions, next) {
                const context = localStorage.getItem(PROFILE_CONTEXT)
                const { id, type } = JSON.parse(context)
                operationOptions.variables['X-profile-type'] = type
                operationOptions.variables['X-profile-id'] = id
                next()
            }
        }
    ])
    apolloClient.wsClient = wsClient

    apolloClient.cache.writeData({
        data: {
            isLoggedIn: !!localStorage.getItem('apollo-token')
        }
    })
    // Create vue apollo provider
    const apolloProvider = new VueApollo({
        defaultClient: apolloClient,
        defaultOptions: {
            $query: {
                // fetchPolicy: 'cache-and-network',
            }
        },
        errorHandler(/* error */ { graphQLErrors, networkError }) {
            if (graphQLErrors) {
                graphQLErrors.map(({ message, locations, path }) =>
                    console.log(
                        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                    )
                )
            }
            if (networkError) {
                // Add something like this to set the error message to the one from the server response
                // networkError.message = networkError.result.errors[0].debugMessage

                console.error(`[Network error]: ${networkError.name}`)
                console.log({ networkError })
                networkError.result.errors.forEach(({ message, extensions: { code } }) => {
                    console.error(`Code:`, code)
                    console.error(`Message:`, message)
                })
            }
            // eslint-disable-next-line no-console
            // console.log('error handler in vue-apollo.js')
            // console.log(
            //     '%cError',
            //     'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
            //     error.message
            // )
        }
    })

    return apolloProvider
}

// Manually call this when user log in
// export async function onLogin(apolloClient, token) {
//     if (typeof localStorage !== 'undefined' && token) {
//         localStorage.setItem(AUTH_TOKEN, token)
//     }
//     // if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
//     try {
//         await apolloClient.cache.reset()
//     } catch (e) {
//         // eslint-disable-next-line no-console
//         console.log('%cError on cache reset (login)', 'color: orange;', e.message)
//     }
// }

// // Manually call this when user log out
// export async function onLogout(apolloClient) {
//     if (typeof localStorage !== 'undefined') {
//         localStorage.removeItem(AUTH_TOKEN)
//     }
//     // if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
//     try {
//         // await apolloClient.resetStore()
//         await apolloClient.cache.reset()
//         console.log('Cache after logging out:', apolloClient.cache)
//         /**
//          * reload the page on logout to prevent user data leaking.
//          */
//         location.reload()
//     } catch (e) {
//         // eslint-disable-next-line no-console
//         console.log('%cError on cache reset (logout)', 'color: orange;', e.message, { e })
//     }
// }
