export const profileImageFragment = `
    profile {
        id
        url
        formats
        name
    }
`

export const coverImageFragment = `
    cover {
        id
        url
        formats
        name
    }
`

export const mediaFullFragment = `
    media {
        id
        ${profileImageFragment}
        ${coverImageFragment}
    }
`

export const mediaProfileImageOnlyFragment = `
    media {
        id
        ${profileImageFragment}
    }
`

export const contactFragment = `
    contact {
        phone
        email
        web
    }
`

export const addressFragment = `
    address {
        street
        zip
        city
        place
    }
`

export const priceRangeFragment = `
    priceMin
    priceMax
    priceMinWeekend
    priceMaxWeekend
`

export const profileMetaFragment = `
    id
    createdAt
    description
    name
    status
    published_at
    meta
    updatedAt
`

export const basicFanFragment = `
    fan {
        id
        name
        media {
            id
            profile {
                id
                url
            }
        }
        avatar @client
    }
`
export const basicArtistFragment = `
    artist {
        id
        name
        media {
            id
            profile {
                id
                url
            }
        }
        avatar @client
    }
`
export const basicVenueFragment = `
    venue {
        id
        name
        media {
            id
            profile {
                id
                url
            }
        }
        avatar @client
    }
`
