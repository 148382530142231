<template>
    <div id="app">
        <AuthProvider>
            <ProfileProvider>
                <NotificationProvider>
                    <router-view></router-view>
                </NotificationProvider>
            </ProfileProvider>
        </AuthProvider>
    </div>
</template>

<script>
import ProfileProvider from './providers/ProfileProvider'
import AuthProvider from './providers/AuthProvider'
import NotificationProvider from './providers/NotificationProvider'
export default {
    name: 'App',
    components: {
        AuthProvider,
        ProfileProvider,
        NotificationProvider
    }
}
</script>

<style lang="scss">
@import 'sass/app';
</style>
