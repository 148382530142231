import gql from 'graphql-tag'
export const REGISTER_SMS = gql`
    mutation RegisterSMS($number: String!) {
        registerSMS(number: $number) {
            type: __typename
            ... on RegisterSMSResponse {
                success
            }
            ... on ResponseError {
                errors {
                    message
                    code
                    field
                }
            }
        }
    }
`

export const VALIDATE_SMS = gql`
    mutation ValidateSMSCode($number: String!, $code: Int!) {
        validateSMSCode(number: $number, code: $code) {
            type: __typename
            ... on ResponseError {
                errors {
                    message
                    code
                    field
                }
            }
            ... on RegisterSMSResponse {
                success
            }
        }
    }
`

export const REGISTER_USER = gql`
    mutation Register($input: UsersPermissionsRegisterInput!) {
        registerUser(input: $input) {
            type: __typename
            ... on ResponseError {
                errors {
                    message
                    code
                    field
                }
            }
            ... on UsersPermissionsLoginPayload {
                jwt
                user {
                    id
                    fan {
                        id
                        name
                    }
                }
            }
        }
    }
`

export const UPDATE_MYUSER = gql`
    mutation Update($input: updateMeInput!) {
        updateMe(input: $input) {
            type: __typename
            ... on ResponseError {
                errors {
                    message
                    code
                    field
                }
            }
            ... on updateMyUserPayload {
                user {
                    id
                }
            }
        }
    }
`

export const LOGIN_USER = gql`
    mutation($input: UsersPermissionsLoginInput!) {
        login(input: $input) {
            type: __typename
            ... on UsersPermissionsLoginPayload {
                jwt
                user {
                    id
                    fan {
                        id
                    }
                }
            }
        }
    }
`
