import Vue from 'vue'
import Vuerx from 'vue-rx'
import VueMq from 'vue-mq'
import faker from 'vue-faker'
import clipper from 'vuejs-clipper'
import router from './router'
import App from './App.vue'
import { createProvider } from './vue-apollo'

import Splash from './views/Splash'
import Button from './components/Button'
import BaseInput from './components/BaseInput'
import ScrollableContainer from './components/layout/ScrollableContainer'
import JumboButton from './components/JumboButton'
import LoadingEllipsis from './components/loading-indicators/LoadingEllipsis'
import { resolvers, typeDefs } from './resolvers.js'

import { Capacitor, Plugins } from '@capacitor/core'

// if (!localStorage.getItem('apollo-item')) {
//     localStorage.removeItem('profile-context')
// }

const { App: Appy } = Plugins

// https://app.goosebumper.no/back?eventID=6005bf7de7906600175f1532
Appy.addListener('appUrlOpen', function(data) {
    const params = data.url.split('?').pop()
    if (params) {
        router.push({
            path: `/success?${params}`
        })
    }
})

if (Capacitor.isNative) {
    // Lock screen to portrait mode on ios/android
    window.screen.orientation.lock('portrait')
}

Vue.use(Vuerx)
Vue.use(VueMq, {
    breakpoints: {
        sm: 768,
        md: 1025,
        lg: Infinity
    }
})
Vue.use(clipper)
Vue.use(faker, { locale: 'nb_NO' })
Vue.component('Splash', Splash)
Vue.component('LoadingEllipsis', LoadingEllipsis)
Vue.component('Button', Button)
Vue.component('BaseInput', BaseInput)
Vue.component('ScrollableContainer', ScrollableContainer)
Vue.component('JumboButton', JumboButton)

/* import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Sentry.init({
    Vue,
    dsn: "https://8e677e81407a4b299c6d4c6d00c11994@o493165.ingest.sentry.io/5561700",
    autoSessionTracking: true,
    logErrors: true,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
}); */

const apolloProvider = createProvider({ typeDefs, resolvers })
new Vue({
    router,
    apolloProvider,
    render: (h) => h(App)
}).$mount('#app')
