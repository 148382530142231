<template>
    <input
        ref="input"
        class="gb-input"
        :class="classes"
        v-bind="$attrs"
        @input="$emit('input', $event.target.value)"
        @blur="$emit('blur', $event)"
    />
</template>

<script>
export default {
    name: 'BaseInput',
    props: {
        isInvalid: {
            type: Boolean,
            default: false
        },
        hasError: {
            type: Boolean,
            default: false
        },
        round: {
            type: Boolean,
            default: false
        },
        textAlign: {
            type: String,
            default: 'left',
            validator(value) {
                return ['left', 'center', 'right'].indexOf(value) !== -1
            }
        },
        autofocus: {
            type: Boolean,
            default: false
        }
    },
    emits: ['input', 'blur'],
    computed: {
        classes() {
            return {
                'gb-input--round': this.round,
                'gb-input--error': this.isInvalid,
                'gb-input--invalid': this.isInvalid,
                [`gb-input--text-align-${this.textAlign}`]: true
            }
        }
    },
    mounted() {
        if (this.autofocus) {
            this.$nextTick().then(() => {
                this.focus()
            })
        }
    },
    methods: {
        focus() {
            this.$refs.input.focus()
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/variables';

::-webkit-datetime-edit {
    padding: 0;
    text-align: left;
}
::-webkit-datetime-edit-fields-wrapper {
    padding: 0;
    text-align: left;
}
::-webkit-datetime-edit-text {
    padding: 0;
    text-align: left;
}
::-webkit-datetime-edit-month-field {
    padding: 0;
}
::-webkit-datetime-edit-day-field {
    padding: 0;
}
::-webkit-datetime-edit-year-field {
    padding: 0;
}
::-webkit-inner-spin-button {
    padding: 0;
    display: block;
}

::-webkit-calendar-picker-indicator {
    padding: 0;
    margin: 0;
    width: 14px;
    height: 14px;
    &:focus {
        outline: 0;
    }
}

.gb-input {
    padding: 1rem;
    font-size: 1.125rem;
    line-height: 1.25rem;
    border: 0;
    outline: 0;
    border-radius: 0.25rem;
    font-family: inherit;
    font-weight: 500;
    width: 100%;
    color: $primary;
    &:active {
        box-shadow: 0 0 1px 2px $primary;
        &.gb-input--error {
            box-shadow: 0 0 1px 1px red;
        }
    }

    &:focus {
        box-shadow: 0 0 1px 1px $primary;
        &.gb-input--error {
            box-shadow: 0 0 1px 1px red;
        }
    }

    &::placeholder {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 17px;
        color: $primary;
    }

    &:disabled {
        background: transparent;
        box-shadow: 0 0 1px 1px $primary;
    }

    &[type='date'] {
        font-family: inherit;
        background-color: white;
        padding: 1rem;
        line-height: 1rem;
        margin: 0;
        border-radius: 0;
        &::-webkit-calendar-picker-indicator {
            background-image: url('~@/assets/icons/calendar_icon.svg');
        }
    }

    &[type='time'] {
        font-family: inherit;
        background-color: white;
        padding: 1rem 1rem;
        line-height: 1rem;
        margin: 0;
        border-radius: 0;
        &::-webkit-calendar-picker-indicator {
            background-image: url('~@/assets/icons/clock_icon.svg');
        }
    }

    &--invalid {
        &::placeholder {
            color: red;
        }
        box-shadow: 0 0 1px 1px red;
    }

    &--text-align-left {
        text-align: left;
    }

    &--text-align-center {
        text-align: center;
    }

    &--text-align-right {
        text-align: right;
    }

    &--round {
        border-radius: 3rem;
    }
}
</style>
