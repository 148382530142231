<template>
    <button
        class="jumbo-button bg-primary text-beige"
        type="button"
        v-bind="$attrs"
        @click="$emit('click', $event)"
    >
        {{ label }}
    </button>
</template>

<script>
export default {
    name: 'JumboButton',
    props: {
        label: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    position: absolute;
}
.jumbo-button {
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0;
    width: 100%;
    height: 90px;
    border-width: 0;
    font-size: 18px;
    font-weight: 500;
}
</style>