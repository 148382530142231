<template>
    <div class="splash bg-primary text-light">
        <div class="bird-wrapper">
            <img alt="" class="bird-shadow" src="@/assets/bird_splash_shadow.png">
            <img alt="" class="bird" src="@/assets/bird_splash.png">
        </div>
        <div class="progress">
            <div class="bar"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Splash"
}
</script>

<style lang="scss" scoped>
@import "@/sass/variables";

@keyframes progressbar {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

.splash {
    min-height: 100vh;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    z-index: 200;

    .bird {
        position: absolute;
        z-index: 2;
        bottom: 0;
        right: 0;

        &-wrapper {
            position: relative;
            background: linear-gradient(90deg, rgba(244, 242, 253, .2), rgba(244, 242, 253, .2));
            border-radius: 50%;
            height: 214px;
            width: 214px;
        }

        &-shadow {
            position: absolute;
            bottom: 0;
            right: 10px;
            z-index: 1;
        }
    }

    .progress {
        width: 150px;
        height: 4px;
        border: 1px solid $light;
        border-radius: 10px;

        .bar {
            animation: 1500ms progressbar linear;
            border:0;
            background: $light;
            height: 100%;
            width: 100%;
        }
    }
}


</style>