import gql from 'graphql-tag'

export const READ_ALL_NOTIFICATIONS = gql`
    mutation SetAllNoticationRead {
        setAllNotificationRead
    }
`

export const READ_NOTIFICATION = gql`
    mutation SetNotificationRead($id: ID!) {
        setNotificationRead(id: $id)
    }
`
