import gql from 'graphql-tag'
import { IS_LOGGED_IN_QUERY } from '@/graphql/queries/local.js'
import {
    MY_FAN_PROFILE,
    MY_ARTIST_PROFILE,
    MY_VENUE_PROFILE,
    GET_CURRENT_PROFILE
} from '@/graphql/queries/user.js'

import { MY_CONVERSATION } from '@/graphql/queries/conversation'
import { uniques } from '@/utils/common.js'
const setDefaultProfileImage = (media, defaultUrl) => {
    if (media && media.profile && media.profile.url) {
        return media.profile.url
    } else {
        return defaultUrl
    }
}

const setDefaultCoverImage = (media, defaultUrl) => {
    if (media.cover) {
        return media
    } else {
        return {
            ...media,
            cover: {
                __typename: 'UploadImage',
                id: -1,
                url: defaultUrl,
                formats: {},
                name: 'default_cover'
            }
        }
    }
}

const defaultProfileImgDict = {
    fan: require('@/assets/bird_portrait_fan.svg'),
    artist: require('@/assets/bird_portrait_artist.svg'),
    venue: require('@/assets/bird_portrait_venue.svg')
}

export const typeDefs = gql`
    type RegisterState {
        username: String!
        email: String!
        password: String!
        phoneNumber: String!
        smsCode: String!
        fullname: String!
        profileName: String!
    }

    type ProfileContext {
        id: String!
        type: String!
    }
`

const getQuery = (type) => {
    switch (type) {
        case 'fan':
            return MY_FAN_PROFILE
        case 'artist':
            return MY_ARTIST_PROFILE
        case 'venue':
            return MY_VENUE_PROFILE
        default:
            throw new Error('Unknown profile type')
    }
}
const handleSwitchProfile = async (cache, type) => {
    const query = getQuery(type)
    const { myProfile, myArtistProfile, myVenueProfile } = await cache.readQuery({ query })
    cache.writeQuery({
        query: GET_CURRENT_PROFILE,
        data: {
            currentProfile: myProfile || myArtistProfile || myVenueProfile
        }
    })
}

export const resolvers = {
    Query: {
        async currentProfile(_, __, { cache }) {
            const { myProfile } = await cache.readQuery({
                query: MY_FAN_PROFILE
            })
            return myProfile
        },
        myConversation(obj, args, context) {
            try {
                const { myConversation } = context.cache.readQuery({
                    query: MY_CONVERSATION,
                    variables: { id: args.id, start: args.start, limit: args.limit }
                })
                const newMessages = uniques(
                    obj.myConversation.messages.concat(myConversation.messages),
                    'id'
                )
                obj.myConversation.messages = newMessages
                return obj
            } catch (error) {
                console.log('Initial conversation resolver')
            }
        }
    },
    Mutation: {
        async switchToFanProfile(_, __, { cache }) {
            handleSwitchProfile(cache, 'fan')
        },
        switchToArtistProfile(_, __, { cache }) {
            handleSwitchProfile(cache, 'artist')
        },
        async switchToVenueProfile(_, __, { cache }) {
            handleSwitchProfile(cache, 'venue')
        },
        setLoggedInState(_, { state }, { cache }) {
            cache.writeQuery({
                query: IS_LOGGED_IN_QUERY,
                data: {
                    isLoggedIn: state
                }
            })
            return null
        }
    },
    ProfileSummary: {
        avatar({ media, type }) {
            return setDefaultProfileImage(media, defaultProfileImgDict[type])
        }
    },
    ConversationProfile: {
        avatar({ media, type }) {
            return setDefaultProfileImage(media, defaultProfileImgDict[type])
        }
    },
    Event: {
        statusText({ status }) {
            switch (status) {
                case 'IN_PROGRESS':
                    return 'OPEN'
                case 'COMPLETE':
                    return 'ACCEPTED'
                default:
                    return 'DRAFT'
            }
        },
        locationText({ address = {}, venue }) {
            if (venue) {
                if (!address) {
                    return `${venue.name}`
                } else {
                    return `${venue.name}, ${address.place}`
                }
            } else {
                if (!address) {
                    return 'Unknown location'
                } else {
                    return address.place
                }
            }
        }
    },
    Eventrequest: {
        statusText({ status }) {
            return status.toLowerCase()
        }
    },
    ArtistSuggestion: {
        avatar({ media }) {
            return setDefaultProfileImage(media, require('@/assets/bird_portrait_artist.svg'))
        }
    },
    VenueSuggestion: {
        avatar({ media }) {
            return setDefaultProfileImage(media, require('@/assets/bird_portrait_artist.svg'))
        }
    },
    Fan: {
        avatar({ media }) {
            return setDefaultProfileImage(media, require('@/assets/bird_portrait_fan.svg'))
        },
        coverUrl({ media }) {
            if (media && media.cover && media.cover.url) {
                return media.cover.url
            } else {
                return require('@/assets/concert.png')
            }
        },
        media({ media }) {
            return setDefaultCoverImage(media, require('@/assets/concert.png'))
        },
        displayName({ name }) {
            return name || 'Your fan profile'
        }
    },
    Artist: {
        avatar({ media }) {
            return setDefaultProfileImage(media, require('@/assets/bird_portrait_artist.svg'))
        },
        media({ media }) {
            return setDefaultCoverImage(media, require('@/assets/concert.png'))
        },
        coverUrl({ media }) {
            if (media && media.cover && media.cover.url) {
                return media.cover.url
            } else {
                return require('@/assets/concert.png')
            }
        },
        displayName({ name }) {
            return name || 'Your artist profile'
        }
    },
    Venue: {
        avatar({ media }) {
            return setDefaultProfileImage(media, require('@/assets/bird_portrait_venue.svg'))
        },
        media({ media }) {
            return setDefaultCoverImage(media, require('@/assets/concert.png'))
        },
        coverUrl({ media }) {
            if (media && media.cover && media.cover.url) {
                return media.cover.url
            } else {
                return require('@/assets/concert.png')
            }
        },
        displayName({ name }) {
            return name || 'Your venue profile'
        }
    }
}
