import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import { AUTH_TOKEN } from '../constants/settings'
// import middlewarePipeline from './middlewarePipeline'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    scrollBehavior: function(to) {
        if (to.hash) {
            setTimeout(() => {
                const element = document.getElementById(to.hash.replace(/#/, '') + 'test')
                if (element) {
                    element.parentElement.scrollLeft = element.offsetLeft
                }
            })
        }
    },
    base: '/',
    linkExactActiveClass: 'active',
    linkActiveClass: 'active',
    routes
})

router.beforeEach((to, from, next) => {
    // Check if the user is logged i
    const auth = localStorage.getItem(AUTH_TOKEN)
    // middleware testing
    /* if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware;
    const context = {
        to,
        from,
        next,
        // store
    }
    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    }) */

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        // This route requires auth, check if logged in.
        // If not, redirect to login page.
        if (auth) {
            next()
        } else {
            next({ path: '/login' })
        }
    } else if (to.matched.some((record) => record.meta.hideForAuth)) {
        // This route should be hidden if you are authenticated.
        // If so, redirect to dashboard.
        if (auth) {
            next({ path: '/dashboard' })
        } else {
            next()
        }
    } else {
        // Go wherever im going.
        next()
    }
})

export default router
