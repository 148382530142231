<template>
    <div class="container">
        <slot name="before" />
        <div class="scrollable">
            <slot />
        </div>
        <slot name="after" />
    </div>
</template>

<script>
export default {
    name: 'ScrollableContainer'
}
</script>

<style lang="scss" scoped>
.container {
    height: 100%;
    overflow: hidden;
    position: relative;
}
.scrollable {
    height: 100%;
    overflow: auto;
}
</style>