<script>
import { ME, MY_ARTIST_PROFILE, MY_FAN_PROFILE, MY_VENUE_PROFILE } from '@/graphql/queries/user.js'
import {
    UPLOAD_IMAGE,
    UPDATE_FAN_PROFILE,
    UPDATE_ARTIST_PROFILE,
    UPDATE_VENUE_PROFILE
} from '@/graphql/mutations/user.js'
import { IS_LOGGED_IN_QUERY } from '@/graphql/queries/local.js'

import { AUTH_TOKEN, PROFILE_CONTEXT } from '../constants/settings'
import logger from '@/utils/logger.js'
const profileQueries = new Map([
    ['fan', MY_FAN_PROFILE],
    ['artist', MY_ARTIST_PROFILE],
    ['venue', MY_VENUE_PROFILE]
])

const profileTypeMap = new Map([
    ['fan', 'myFanProfile'],
    ['artist', 'myArtistProfile'],
    ['venue', 'myVenueProfile']
])

export default {
    name: 'ProfileProvider',
    apollo: {
        isLoggedIn: {
            query: IS_LOGGED_IN_QUERY
        },
        myUser: {
            query: ME,
            skip() {
                return !this.isLoggedIn
            },
            update({ me }) {
                return me
            },
            result({ data }) {
                const context = localStorage.getItem(PROFILE_CONTEXT)
                if (!context && this.isLoggedIn) {
                    localStorage.setItem(
                        PROFILE_CONTEXT,
                        JSON.stringify({
                            type: 'fan',
                            id: data.me.fan.id
                        })
                    )
                }
            },
            error(error) {
                // invalid token, handle it!
                console.error('Error Fetching ME in ProfileProvider')
                logger.log({ error })
                // TMP - handle this
                if (error && error.message === 'GraphQL error: Invalid token.') {
                    // token expired
                    localStorage.removeItem(AUTH_TOKEN)
                    localStorage.removeItem(PROFILE_CONTEXT)
                    // redirect user to login/front page?
                    this.$router.push('/login')
                }
            }
        },
        myFanProfile: {
            query: MY_FAN_PROFILE,
            skip() {
                return !this.isLoggedIn
            },
            update({ myProfile }) {
                return myProfile
            },
            error(error) {
                console.error('Error Fetching MY_PROFILE in ProfileProvider', {
                    error
                })
            }
        },
        myArtistProfile: {
            query: MY_ARTIST_PROFILE,
            skip() {
                return !this.isLoggedIn || !this?.myUser?.artists?.[0]
            },
            error(error) {
                console.error('Error Fetching MY_ARTIST_PROFILE in ProfileProvider', error)
            }
        },
        myVenueProfile: {
            query: MY_VENUE_PROFILE,
            skip() {
                return !this.isLoggedIn
            },
            error(error) {
                console.error('Error Fetching MY_VENUE_PROFILE in ProfileProvider', error)
            }
        }
    },
    provide() {
        return {
            getMyCurrentProfile: () => this.getMyCurrentProfile,
            getCurrentProfileType: () => this.currentProfileType,
            isProfileLoading: () => this.loading,
            switchToFanProfile: this.switchToFanProfile,
            switchToArtistProfile: this.switchToArtistProfile,
            switchToVenueProfile: this.switchToVenueProfile,
            uploadImage: this.uploadImage,
            updateProfile: this.update
        }
    },
    data() {
        return {
            myUser: null,
            myFanProfile: {},
            myArtistProfile: null,
            myVenueProfile: null,
            currentProfileType: 'fan'
        }
    },
    computed: {
        getMyCurrentProfile() {
            return this[profileTypeMap.get(this.currentProfileType)]
        },
        loading() {
            return this.$apollo.loading
        },
        isCurrentProfileLoading() {
            return this.$apollo.queries[profileTypeMap.get(this.currentProfileType)].loading
        }
    },
    created() {
        // Set current profile type from localStorage
        const context = localStorage.getItem(PROFILE_CONTEXT)
        if (!context) {
            this.currentProfileType = 'fan'
        } else {
            const { type } = JSON.parse(context)
            this.currentProfileType = type
        }
    },
    methods: {
        logError(error) {
            console.error(error)
            error.graphQLErrors.map((error) => {
                console.log('GraphQL error:', error)
            })
        },
        switchToFanProfile() {
            this.handleChangeProfile('fan')
        },
        switchToArtistProfile() {
            this.handleChangeProfile('artist')
        },
        switchToVenueProfile() {
            this.handleChangeProfile('venue')
        },
        handleChangeProfile(type) {
            logger.log(`Attempting to switch to profile type ${type}`)

            const isValid = [...profileTypeMap.keys()].includes(type)

            if (!isValid) {
                logger.error(`Invalid profile type: ${type}`)
                return
            }

            if (type === this.currentProfileType) {
                logger.log(`User is already on profile type ${type}`)
                return
            }

            if (!this[profileTypeMap.get(type)]) {
                logger.error('Profile does not exist')
                return
            }

            this.currentProfileType = type

            localStorage.setItem(
                PROFILE_CONTEXT,
                JSON.stringify({
                    id: this.getMyCurrentProfile.id,
                    type
                })
            )
        },
        async refetchMyProfiles() {
            await this.$apollo.queries.myFanProfile.refetch()
            await this.$apollo.queries.myArtistProfile.refetch()
            await this.$apollo.queries.myVenueProfile.refetch()
        },
        update(payload) {
            switch (this.currentProfileType) {
                case 'fan':
                    return this.updateFanProfile(payload)
                case 'artist':
                    return this.updateArtistProfile(payload)
                case 'venue':
                    return this.updateVenueProfile(payload)
                default:
                    throw new Error('Unrecognized profile')
            }
        },

        async updateVenueProfile({ contact, address, ...payload }) {
            try {
                const result = await this.$apollo.mutate({
                    mutation: UPDATE_VENUE_PROFILE,
                    variables: {
                        input: {
                            where: {
                                id: this.myVenueProfile.id
                            },
                            data: {
                                ...payload,
                                contact,
                                address
                            }
                        }
                    },
                    update: this.updateCacheOnVenueProfileUpdate,
                    optimisticResponse: {
                        __typename: 'Mutation',
                        updateVenueProfile: {
                            __typename: 'updateVenuePayload',
                            type: 'updateVenuePayload',
                            venue: {
                                ...this.getMyCurrentProfile,
                                ...payload,
                                contact: {
                                    __typename: 'ComponentProfileContact',
                                    ...contact
                                },
                                address: {
                                    __typename: 'ComponentProfileAddress',
                                    ...address
                                }
                            }
                        }
                    }
                })

                return result.data.updateVenueProfile
            } catch (error) {
                console.error('Error when updating venue profile:', { error })
            }
        },
        async updateFanProfile(payload) {
            logger.log(payload)
            try {
                // eslint-disable-next-line no-unused-vars
                const { categories, ...payloadRest } = payload
                const result = await this.$apollo.mutate({
                    mutation: UPDATE_FAN_PROFILE,
                    variables: {
                        input: {
                            where: {
                                id: this.myFanProfile.id
                            },
                            data: {
                                ...payloadRest,
                                interests: payload.categories
                            }
                        }
                    },
                    update: this.updateCacheOnFanProfileUpdate,
                    optimisticResponse: {
                        __typename: 'Mutation',
                        updateFanProfile: {
                            __typename: 'updateFanPayload',
                            type: 'updateFanPayload',
                            fan: {
                                ...this.getMyCurrentProfile,
                                ...payloadRest,
                                interests: payload.categories
                            }
                        }
                    }
                })
                return result.data.updateFanProfile
            } catch (error) {
                console.error('Error when updating FAN profile:', { error })
            }
        },
        async updateArtistProfile({ contact, ...payload }) {
            try {
                const result = await this.$apollo.mutate({
                    mutation: UPDATE_ARTIST_PROFILE,
                    variables: {
                        input: {
                            where: {
                                id: this.myArtistProfile.id
                            },
                            data: {
                                ...payload,
                                contact
                            }
                        }
                    },
                    update: this.updateCacheOnArtistProfileUpdate,
                    optimisticResponse: {
                        __typename: 'Mutation',
                        updateArtistProfile: {
                            __typename: 'updateArtistPayload',
                            type: 'updateArtistPayload',
                            artist: {
                                ...this.getMyCurrentProfile,
                                ...payload,
                                contact: {
                                    __typename: 'ComponentProfileContact',
                                    ...contact
                                }
                            }
                        }
                    }
                })
                return result.data.updateArtistProfile
            } catch (error) {
                console.error('Error when updating ARTIST profile:', { error })
            }
        },
        updateCacheOnUpload(mediaType) {
            let self = this
            let profileResponseName = {
                fan: 'myProfile',
                artist: 'myArtistProfile',
                venue: 'myVenueProfile'
            }[this.currentProfileType]
            return function(cache, { data: { upload } }) {
                const query = profileQueries.get(self.currentProfileType)
                const data = cache.readQuery({ query })
                const profile = data[profileResponseName]
                const { media } = profile
                // const { id, ...uploadRest } = upload
                const newMediaRef = {
                    ...media,
                    [`${mediaType}`]: upload
                }
                if (mediaType === 'profile') {
                    profile.avatar = newMediaRef.profile.url
                }
                if (mediaType === 'cover') {
                    profile.coverUrl = newMediaRef.cover.url
                }
                cache.writeQuery({
                    query: profileQueries.get(self.currentProfileType),
                    data: {
                        [`${profileResponseName}`]: {
                            ...profile,
                            media: newMediaRef
                        }
                    }
                })
            }
        },

        // eslint-disable-next-line no-unused-vars
        updateCacheOnFanProfileUpdate(cache, { data: { updateFanProfile } }) {
            const {
                myProfile: { name, ...profile }
            } = cache.readQuery({ query: MY_FAN_PROFILE })
            cache.writeQuery({
                query: MY_FAN_PROFILE,
                data: {
                    myProfile: {
                        ...profile,
                        name,
                        displayName: name ? name : 'Your fan profile'
                    }
                }
            })
        },
        // eslint-disable-next-line no-unused-vars
        updateCacheOnArtistProfileUpdate(cache, { data: { updateArtistProfile } }) {
            const {
                myArtistProfile: { name, ...profile }
            } = cache.readQuery({ query: MY_ARTIST_PROFILE })
            cache.writeQuery({
                query: MY_ARTIST_PROFILE,
                data: {
                    myArtistProfile: {
                        ...profile,
                        name,
                        displayName: name ? name : 'Your artist profile'
                    }
                }
            })
        },
        // eslint-disable-next-line no-unused-vars
        updateCacheOnVenueProfileUpdate(cache, { data: { updateVenueProfile } }) {
            const {
                myVenueProfile: { name, ...profile }
            } = cache.readQuery({ query: MY_VENUE_PROFILE })
            cache.writeQuery({
                query: MY_VENUE_PROFILE,
                data: {
                    myVenueProfile: {
                        ...profile,
                        name,
                        displayName: name ? name : 'Your venue profile'
                    }
                }
            })
        },
        /**
         * Upload an image, either profile or cover, to a profile's media context.
         * @param {ID} id - The id of the media context for the profile that the image is being uploaded to.
         * @param {string} type - One of either profile or cover.
         * @param {file} file - The image file of which you want to upload.
         */
        uploadImage(id, type, file) {
            logger.log('Uploading image with', id, type, file)
            return this.$apollo.mutate({
                mutation: UPLOAD_IMAGE,
                variables: {
                    file: file,
                    refId: id,
                    ref: 'profile.image',
                    field: `${type}`
                },
                update: this.updateCacheOnUpload(type),
                optimisticResponse: {
                    __typename: 'Mutation',
                    upload: {
                        __typename: 'UploadFile',
                        id: -1,
                        name: type.toLowerCase(),
                        url: URL.createObjectURL(file),
                        previewUrl: null,
                        formats: null
                    }
                }
            })
        }
    },
    render() {
        return this.$slots.default
    }
}
</script>
