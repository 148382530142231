<template>
    <div class="loader">
        <slot />
        <span class="ellipsis-anim">
            <span>.</span>
            <span>.</span>
            <span>.</span>
        </span>
    </div>
</template>

<script>
export default { name: 'Loading' }
</script>

<style lang="scss" scoped>
@-webkit-keyframes ellispis-dot {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes ellipsis-dot {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.loader {
    font-size: 18px;
}
.ellipsis-anim {
    span {
        opacity: 0;
        -webkit-animation: ellipsis-dot 1s infinite;
        animation: ellipsis-dot 1s infinite;

        &:nth-child(1) {
            -webkit-animation-delay: 0s;
            animation-delay: 0s;
        }
        &:nth-child(2) {
            -webkit-animation-delay: 0s;
            animation-delay: 0.1s;
        }
        &:nth-child(3) {
            -webkit-animation-delay: 0s;
            animation-delay: 0.2s;
        }
    }
}
</style>