import gql from 'graphql-tag'

export const MY_CONVERSATION = gql`
    query MyConversation($id: ID!, $limit: Int!, $start: Int!) {
        myConversation(id: $id, limit: $limit, start: $start) {
            id
            lastMessage
            createdBy
            members {
                isMyProfile
                isInitiator
                type
                id
                name
                media {
                    profile {
                        url
                    }
                }
                avatar @client
            }
            messages {
                id
                message
                createdBy
                profileId
            }
        }
    }
`

export const MY_CONVERSATIONS = gql`
    query MyConversations {
        myConversations {
            id
            createdAt
            updatedAt
            variant
            lastMessage
            createdBy
            updatedBy
            source
            members {
                id
                type
                name
                media {
                    profile {
                        url
                    }
                }
                isMyProfile
                isInitiator
                avatar @client
            }
        }
    }
`

export const SEND_MESSAGE = gql`
    mutation SendMessage($input: MessageInput!) {
        sendMessage(input: $input) {
            id
            lastMessage
            updatedAt
            source
            members {
                isMyProfile
                isInitiator
                type
                id
                name
                media {
                    profile {
                        url
                    }
                }
            }
        }
    }
`
