<script>
import { READ_NOTIFICATION, READ_ALL_NOTIFICATIONS } from '@/graphql/mutations/notifications.js'
import { IS_LOGGED_IN_QUERY } from '@/graphql/queries/local.js'
import logger from '@/utils/logger.js'
import gql from 'graphql-tag'
const MY_NOTIFICATIONS = gql`
    query MyNotificationSummary {
        myNotificationSummary {
            notifications {
                id
                createdAt
                updatedAt
                context
                reciever
                isRead
                sender {
                    id
                    name
                    media {
                        id
                        profile {
                            id
                            url
                        }
                    }
                    avatar @client
                    type
                }
            }
            summary
        }
    }
`
export default {
    name: 'NotificationProvider',
    inject: ['getCurrentProfileType'],
    provide() {
        return {
            myNotificationsQuery: () => ({
                data: this.myNotificationSummary.notifications.filter(
                    (notification) => notification.fan !== null
                ),
                loading: this.$apollo.queries.myNotificationSummary.loading
            }),
            myNotificationSummary: () => this.myNotificationSummary.summary,
            readAllNotifications: () => ({
                mutate: () => this.readAllNotificationsMutation(),
                loading: this.readAllLoading
            }),
            readNotification: () => ({
                mutate: (id) => this.readNotificationMutation(id),
                loading: this.readNotificationLoading
            })
        }
    },
    apollo: {
        isLoggedIn: {
            query: IS_LOGGED_IN_QUERY
        },
        $subscribe: {
            updated: {
                query: gql`
                    subscription UserStatus {
                        userStatus
                    }
                `,
                async result({ data }) {
                    logger.log('sub res: ', data, this.$apollo)
                    await this.$apollo.queries.myNotificationSummary.refetch()
                }
            }
        },
        myNotificationSummary: {
            query: MY_NOTIFICATIONS,
            // pollInterval: 7000,

            skip() {
                return !this.isLoggedIn
            }
        }
    },
    data() {
        return {
            myNotificationSummary: {
                notifications: [],
                summary: {
                    artist: 0,
                    fan: 0,
                    venue: 0
                }
            },
            readAllLoading: 0,
            isLoggedIn: false
        }
    },
    computed: {
        currentProfileType() {
            return this.getCurrentProfileType()
        }
    },
    watch: {
        currentProfileType() {
            logger.log('Refreshing notifications')
            this.myNotificationSummary.notifications = []
            this.$apollo.queries.myNotificationSummary.refetch()
        }
    },
    methods: {
        readAllNotificationsMutation() {
            this.readAllLoading++
            return this.$apollo
                .mutate({
                    mutation: READ_ALL_NOTIFICATIONS,
                    optimisticResponse: {
                        __typename: 'Mutation',
                        setAllNotificationRead: true
                    },
                    update(cache, { data: { setAllNotificationRead } }) {
                        const { myNotificationSummary } = cache.readQuery({
                            query: MY_NOTIFICATIONS
                        })
                        myNotificationSummary.notifications.forEach(
                            (notification) => (notification.isRead = setAllNotificationRead)
                        )
                        cache.writeQuery({
                            query: MY_NOTIFICATIONS,
                            data: {
                                myNotificationSummary
                            }
                        })
                    }
                })
                .then(() => this.readAllLoading--)
                .catch(() => this.readAllLoading--)
        },
        readNotificationMutation(id) {
            this.readNotificationLoading++
            return this.$apollo
                .mutate({
                    mutation: READ_NOTIFICATION,
                    variables: {
                        id
                    },
                    optimisticResponse: {
                        __typename: 'Mutation',
                        setNotificationRead: true
                    },
                    update: (cache, { data: { setNotificationRead } }) => {
                        const { myNotificationSummary } = cache.readQuery({
                            query: MY_NOTIFICATIONS
                        })
                        const notificationRef = myNotificationSummary.notifications.find(
                            (notification) => {
                                return notification.id === id
                            }
                        )

                        notificationRef.isRead = setNotificationRead
                        cache.writeQuery({
                            query: MY_NOTIFICATIONS,
                            data: {
                                myNotificationSummary
                            }
                        })
                    }
                })
                .then(() => this.readNotificationLoading--)
                .catch(() => this.readNotificationLoading--)
        }
    },
    render() {
        return this.$slots.default
    }
}
</script>
